import { createDataForSubmitOrder } from "./helper";
import { hotTubAPI } from "../../api";

export const ActionTypes = {
  SUBMIT_FORM: "CART/SUBMIT_FORM",
  SUBMIT_FORM_SUCCESS: "CART/SUBMIT_FORM_SUCCESS",
  SUBMIT_FORM_FAILURE: "CART/SUBMIT_FORM_FAILURE",
  SET_KIND_OF_DELIVERY: "CART/SET_KIND_OF_DELIVERY",
  GET_PAY_SYSTEM_LIST: "HOT_TUB/GET_PAY_SYSTEM_LIST",
  GET_PAY_SYSTEM_LIST_SUCCESS: "HOT_TUB/GET_PAY_SYSTEM_LIST_SUCCESS",
  GET_PAY_SYSTEM_LIST_FAILURE: "HOT_TUB/GET_PAY_SYSTEM_LIST_FAILURE",
};

/*export const setUserDataSuccess = (payload) => {
  return {
      type: ActionTypes.SUBMIT_FORM_SUCCESS,
      payload: payload
  };
}

export const setNeedForDelivery = (payload) => {
  return {
      type: ActionTypes.SET_KIND_OF_DELIVERY,
      payload: payload
  };
}*/

export const getPaySystemsList = (countryId) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.GET_PAY_SYSTEM_LIST });
    const res = await hotTubAPI.getPaySystemsList({ country: countryId ? countryId : localStorage.getItem("countryId") });
    if(res?.status === 200 && res?.data?.length > 0){
      dispatch({ type: ActionTypes.GET_PAY_SYSTEM_LIST_SUCCESS, payload: res.data })
    } else throw new Error("Error when get paySystemsList")
  } catch (err) {
    dispatch({ type: ActionTypes.GET_PAY_SYSTEM_LIST_FAILURE });
    console.error(err);
  }
}

export const setUserData = (data, authorizationToken = "", setStep) => async (dispatch) => {
  try {
    dispatch({ type: ActionTypes.SUBMIT_FORM });
    const formData = createDataForSubmitOrder(data, authorizationToken);
    console.log(formData);
    const res = await hotTubAPI.submitOrder(formData);
    if(res?.data && res?.status === 200) {
      await dispatch({ type: ActionTypes.SUBMIT_FORM_SUCCESS, payload: res.data });
      if(+localStorage.getItem("paymentId") !== 1104){
        await setStep(2);
      }
      console.log('submitOrder', res)
    }
  } catch (err) {
    dispatch({ type: ActionTypes.SUBMIT_FORM_FAILURE });
    console.log(err);
  }
  
}
