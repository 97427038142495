import './style.css';


const CheckIcon = () => {
    return (
        <span className="CheckIcon">
            <div className="CheckIcon-stem"></div>
            <div className="CheckIcon-kick"></div>
        </span>
    )
}

export default CheckIcon;