import React, { useState } from "react";
import { productsHeader, toEuroAmount, totalSum } from "../helpers";
import { useDispatch, useSelector } from "react-redux";
import CartPaymentProduct from "./CartPaymentProduct";
import { Loader } from "../../../Loader";
import "./style.css";
import cn from "classnames";
import { updatePrice } from "../../../../actions/hotTub";
import { hotTubAPI } from "../../../../api";
import { getPaySystemsList, setPaySystemsList } from "../../../../actions/cart";

const CartPaymentOrder = () => {
  
  const cartData = useSelector(state => state.hotTub.cart);
  const cartSummary = useSelector(state => state.hotTub.cartSummary);
  const [radioValue, setRadioValue] = useState(localStorage.getItem("delivery") ? localStorage.getItem("delivery") : "Lieferung");
  const dispatch = useDispatch();
  const onChangeRadio = async (e, textRadioValue) => {
    localStorage.setItem("delivery", textRadioValue);
    setRadioValue(e.target.value);
  
    if(!localStorage.getItem("is_address_delivery")) {
      dispatch(updatePrice({
        countryId: localStorage.getItem("countryId"),
        state: localStorage.getItem("state") ? localStorage.getItem("state") : "",
        city: localStorage.getItem("city") ? localStorage.getItem("city") : "",
        postal_code: localStorage.getItem("postal_code") ? localStorage.getItem("postal_code") : "",
        isAddDeliveryPrice: !!localStorage.getItem("postal_code") && localStorage.getItem("delivery") === "Lieferung"
      }));
    }
  
    if(localStorage.getItem("is_address_delivery")){
      dispatch(updatePrice({
        countryId: localStorage.getItem("deliveryCountryId"),
        state: localStorage.getItem("state") ? localStorage.getItem("state") : "",
        city: localStorage.getItem("delivery_city") ? localStorage.getItem("delivery_city") : "",
        postal_code: localStorage.getItem("delivery_postal_code") ? localStorage.getItem("delivery_postal_code") : "",
        isAddDeliveryPrice: !!localStorage.getItem("delivery_postal_code") && localStorage.getItem("delivery") === "Lieferung"
      }));
    }
  }
  
  if (!cartData) {
    return (
      <div className="CartPaymentOrderWrapper">
        <Loader/>
      </div>
    );
  }
  
  return (
    <div className="CartPaymentOrder">
      <div className="CartPaymentOrder-header">
        {productsHeader.map(( item ) => (
          <div key={item}>{item}</div>
        ))}
      </div>
      {cartData?.["0"].map(( item, i ) => {
          return <React.Fragment key={i}>
            {Object.values(item).map(( product ) => {
              return <CartPaymentProduct key={product.object?._main.id}
                                         product={product}
              />
            })}
            <div className="CartPaymentOrder-totalSum">
              <p>{toEuroAmount(totalSum(item))}</p>
            </div>
          </React.Fragment>
        }
      )}
      <div>
        <div className="CartPaymentOrder-common">
          <p className="CartPaymentOrder-common-name">Zwischensumme</p>
          <p className="CartPaymentOrder-common-price">{toEuroAmount(cartSummary?.sumForAllWithoutVat)}</p>
        </div>
  
        <div className={cn("CartDataSubtotal-transportation-method", localStorage.getItem("postal_code") && "delivery")}>
          
          { localStorage.getItem("postal_code") && cartSummary.vatSum ?
            <div>
              <p className="CartDataSubtotal-transportation-method-title">Versand</p>
              <label className={cn("CartDataSubtotal-transportation-method-radio", radioValue === "Lieferung" && "checked")}>
                <input type="radio"
                       value="Lieferung"
                       checked={radioValue === "Lieferung"}
                       onChange={(e) => onChangeRadio(e, "Lieferung")}
                /> Lieferung: <b>{toEuroAmount(cartSummary?.deliverySum)}</b>
              </label>
              <label className={cn("CartDataSubtotal-transportation-method-radio", radioValue === "Abholung" && "checked")}>
                <input type="radio"
                       value="Abholung"
                       checked={radioValue === "Abholung"}
                       onChange={(e) => onChangeRadio(e, "Abholung")}
                /> Abholung vor Ort
              </label>
            </div> : null}
          { localStorage.getItem("postal_code") && !cartSummary.vatSum && <p className="CartDataSubtotal-transportation-method-title">
            Versand-Versandvorbereitung-Zollgebühren: <b>{toEuroAmount(cartSummary.deliverySum)}</b>
          </p>}
        </div>
        
        <div className="CartPaymentOrder-common">
          <p className="CartPaymentOrder-common-name">Gesamtsumme</p>
          <p className="CartPaymentOrder-common-price">{toEuroAmount(cartSummary?.deliverySumDelivery)}</p>
        </div>
        <div className="CartPaymentOrder-VATIncluded">
          <p className="CartPaymentOrder-VATIncluded-name">inkl. MwSt.</p>
          <p
            className="CartPaymentOrder-VATIncluded-price">{+cartSummary?.vatSum === 0 ? "0,00 €" : toEuroAmount(cartSummary?.vatSum)}</p>
        </div>
      </div>
    </div>
  );
};

export default CartPaymentOrder;
