import React from "react";
import injectMedia from "../../media";
import { Loader } from "../../Loader";
import CartDataProduct from "./CartDataProduct";
import { useDispatch, useSelector } from "react-redux";
import { removeCartItem } from "../../../actions/hotTub";
import { toEuroAmount, totalSum } from "../CartPaymentsMethod/helpers";
import flipPhoneImg from "../../../assets/images/flip-fone-icon-text.png";
import './style.css';


const CartDataProducts = (props) => {

    const cartData = useSelector(state => state.hotTub.cart);
    const dispatch = useDispatch();

    const productsHeader = ['PRODUKT', "", "PREIS", "ANZAHL", "ZWISCHENSUMME"];
    const productsMobileHeader = ['PRODUKT', "", "ANZAHL"];

    const { mobileCartQuery } = props;

    const handleDeleteHotTub = (index) => {
        dispatch(removeCartItem(index));
    }

    if (!cartData) {
        return (
            <div className="CartDataWrapper">
                <Loader />
            </div>
        );
    }

    return (
        <div className="CartDataProducts">
            {mobileCartQuery && <img src={flipPhoneImg} alt='flip phone'/>}
            <div className="CartDataProducts-header">
                {!mobileCartQuery ?
                    productsHeader.map((item) => <span key={item}>{item}</span>) :
                    productsMobileHeader.map((item) => <span key={item}>{item}</span>)}
            </div>
            {cartData.map(configuration => {
                return configuration.map((elem, i) => {
                    return <React.Fragment key={i}>
                        {Object.values(elem).map((product, productIndex) => {
                              return <CartDataProduct key={product.object?._main.id}
                                                      product={product}
                                                      index={productIndex}
                                                      configurationIndex={i}
                                                      onClickDelete={handleDeleteHotTub}
                              />
                          }
                        )}
                        <div className="CartDataProducts-totalSum">
                            {!mobileCartQuery && <p></p>}
                            {!mobileCartQuery && <p></p>}
                            <p></p>
                            <p></p>
                            <p>{toEuroAmount(totalSum(elem))}</p>
        
                        </div>
                    </React.Fragment>
                })
                
                }
            )}

        </div>
    )
};

export default injectMedia(CartDataProducts);