import React from "react";
import injectMedia from "../../../media";
import { toEuroAmount } from "../../CartPaymentsMethod/helpers";
import '../style.css';

const CartDataProduct = ( props ) => {
  
  const { mobileCartQuery, product, index, onClickDelete, configurationIndex } = props;
  
  return (
    <div className="CartDataProducts-product">
      <div className="CartDataProducts-product-delete">
        {index === 0 && <button onClick={() => onClickDelete(configurationIndex)}>×</button>}
      </div>
      <div className="CartDataProducts-product-option">
        <p className="CartDataProducts-product-option-name">{product?.nameCategory && product?.nameCategory}</p>
        <div className="CartDataProducts-product-icons">
          {product.object?.base.cart_image &&
            <img src={`https://${window.partnerPath}` + `${product.object?.base.cart_image}`} alt="product"/>}
        </div>
        <p className="CartDataProducts-product-option-desc">{product.object?._main.Name}</p>
        <p className="CartDataProducts-product-option-desc">{product.object?.base.description}</p>
      </div>
      {!mobileCartQuery && <p className="CartDataProducts-product-price">{toEuroAmount(product.price)}</p>}
      <p className="CartDataProducts-product-amount">{product.count}</p>
      {!mobileCartQuery && <p className="CartDataProducts-product-price">{toEuroAmount(product.priceSum)}</p>}
    </div>
  )
};

export default injectMedia(CartDataProduct);