import { ActionTypes } from "../../actions/cart";

const initialState = {
  userData: {},
  paySystemsList: [],
  isFormSubmitting: false,
  isDelivery: false,
  isLoadingPaySystemList: false
};

const cartReducer = ( state = initialState, action ) => {
  switch (action.type) {
    case ActionTypes.SUBMIT_FORM:
      return {
        ...state,
        isFormSubmitting: true,
      };
    case ActionTypes.SUBMIT_FORM_SUCCESS:
      return {
        ...state,
        userData: action.payload,
        isFormSubmitting: false,
      };
    case ActionTypes.SUBMIT_FORM_FAILURE:
      return {
        ...state,
        isFormSubmitting: false,
      };
    case ActionTypes.SET_KIND_OF_DELIVERY:
      return {
        ...state,
        isDelivery: action.payload,
      };
    /*case ActionTypes.SET_PAY_SYSTEMS_LIST:
      return {
        ...state,
        paySystemsList: action.payload,
      };*/
    case ActionTypes.GET_PAY_SYSTEM_LIST:
      return {
        ...state,
        isLoadingPaySystemList: true
      };
    case ActionTypes.GET_PAY_SYSTEM_LIST_SUCCESS:
      return {
        ...state,
        isLoadingPaySystemList: false,
        paySystemsList: action.payload
      };
    case ActionTypes.GET_PAY_SYSTEM_LIST_FAILURE:
      return {
        ...state,
        isLoadingPaySystemList: false
      };
    default:
      return state;
  }
};

export default cartReducer;
