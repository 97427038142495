export const createDataForUser = (formData) => {
  console.log('formData', formData)
  const data = {...formData};
  if (!data.is_address_delivery) {
    data.delivery_first_name = data.first_name;
    data.delivery_last_name = data.last_name;
    data.delivery_company = data.company;
    data.delivery_country = data.country;
    data.delivery_street = data.street;
    data.delivery_apartment = data.apartment;
    data.delivery_postal_code = data.postal_code;
    data.delivery_city = data.city;
  }
  delete data.is_address_delivery;

  return data;
}

const getPaymentMethodText = (id) => {
  if(id === 1103){
    return "klarna"
  } else if(id === 1104){
    return "paypal"
  } else {
    return "cash"
  }
}

export const createDataForSubmitOrder = ( formData, authorizationToken) => {
  return {
    doNotClearCartItems: 0,
    name: formData.is_address_delivery ? formData.delivery_first_name : formData.first_name,
    email: formData.email,
    phone: formData.phone,
    street: formData.is_address_delivery ? formData.delivery_street : formData.street,
    surname: formData.is_address_delivery ? formData.delivery_last_name : formData.last_name,
    lastname: "",
    city: formData.is_address_delivery ? formData.delivery_city : formData.city,
    room: formData.is_address_delivery ? formData.delivery_apartment : formData.apartment,
    index: formData.is_address_delivery ? formData.delivery_postal_code : formData.postal_code,
    orderData: {
      id: getPaymentMethodText(+localStorage.getItem("paymentId")),
      delivery: "32",
      deliveryTime: "112312331",
      status: "12",
      comments: "comments here",
      store_id: "22",
      notes: formData.notes,
      promo: "", // 909WER32 test promo code
      orderType: "notes here",
      authorizationToken: authorizationToken
    }
  };
}
