import React, { useEffect } from 'react';
import { useStepper } from '../../customHooks/useStepper';

export const StepperSteps = ({ children }) => {
    const { currentStep, steps, setSteps } = useStepper();

    useEffect(() => {
        console.log(children);
        
        const stepperSteps = React.Children.toArray(children).map(step => step.props);
        setSteps(stepperSteps);
    }, [setSteps]);

    return (
        <div>
            {children &&
                React.Children.map(children, child => {
                    if (steps.length) {
                        return child.props.id === steps[currentStep].id
                            ? child
                            : null;
                    }
                })}
        </div>
    );
};

export const StepperStep = ({ children }) => {
    return <>{children}</>;
};
