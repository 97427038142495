import { useEffect, useState } from "react";
import { useStepper } from "../../customHooks/useStepper";
import { useDispatch, useSelector } from "react-redux";
import { toEuroAmount } from "../CartPaymentsMethod/helpers";
import CartDataSubtotalFormik from "./CartDataSubtotalFormik";
import { updatePrice } from "../../../actions/hotTub";
import { Loader } from "../../Loader";
import Collapse from "react-collapse";
import cn from "classnames";
import './style.css';


const CartDataSubtotal = () => {
  
    const [isOpenCollapseBlock, setIsOpenCollapseBlock] = useState(false);
    const [radioValue, setRadioValue] = useState(localStorage.getItem("delivery") ? localStorage.getItem("delivery") : "Lieferung");
    const cartSummary = useSelector(state => state.hotTub.cartSummary);
    const isLoadingUpdatePrice = useSelector(state => state.hotTub.isLoadingUpdatePrice);
    const { setStep } = useStepper();
    const dispatch = useDispatch();

    const onChangeRadio = (e, textRadioValue) => {
      localStorage.setItem("delivery", textRadioValue);
      setRadioValue(e.target.value);
      dispatch(updatePrice({
        countryId: localStorage.getItem("countryId"),
        state: localStorage.getItem("state") ? localStorage.getItem("state") : "",
        city: localStorage.getItem("city") ? localStorage.getItem("city") : "",
        postal_code: localStorage.getItem("postal_code") ? localStorage.getItem("postal_code") : "",
        isAddDeliveryPrice: !!localStorage.getItem("postal_code") && localStorage.getItem("delivery") === "Lieferung"
      }))
    }
    
    useEffect(() => {
      setRadioValue(localStorage.getItem("delivery"));
    }, [localStorage.getItem("delivery")])
    
    return (
        <div className="CartDataSubtotal">
          {
            isLoadingUpdatePrice &&
            <div className="CartDataSubtotal-loader">
              <Loader />
            </div>
          }
            <div className="CartDataSubtotal-header">
                <p>WARENKORB-SUMME</p>
            </div>
            <div className="CartDataSubtotal-title">
                <p className="CartDataSubtotal-title-name">Zwischensumme</p>
                <p className="CartDataSubtotal-title-price">{toEuroAmount(cartSummary?.sumForAllWithoutVat)}</p>
            </div>
            <div className="CartDataSubtotal-transportation">
              <div className={cn("CartDataSubtotal-transportation-method", localStorage.getItem("postal_code") && "delivery")}>
                <p className="CartDataSubtotal-transportation-method-title">Versand</p>
                { localStorage.getItem("postal_code") && cartSummary.vatSum ?
                  <div>
                    <label className={cn("CartDataSubtotal-transportation-method-radio", radioValue === "Lieferung" && "checked")}>
                    <input type="radio"
                           value="Lieferung"
                           checked={radioValue === "Lieferung"}
                           onChange={(e) => onChangeRadio(e, "Lieferung")}
                    /> Lieferung: <b>{toEuroAmount(cartSummary?.deliverySum)}</b>
                  </label>
                    <label className={cn("CartDataSubtotal-transportation-method-radio", radioValue === "Abholung" && "checked")}>
                      <input type="radio"
                             value="Abholung"
                             checked={radioValue === "Abholung"}
                             onChange={(e) => onChangeRadio(e, "Abholung")}
                      /> Abholung vor Ort
                    </label>
                  </div> : null}
                { localStorage.getItem("postal_code") && !cartSummary.vatSum && <p className="CartDataSubtotal-transportation-method-title">
                  Versand-Versandvorbereitung-Zollgebühren: <b>{toEuroAmount(cartSummary.deliverySum)}</b>
                </p>}
                <p className="CartDataSubtotal-transportation-method-title-collapse"
                   onClick={() => setIsOpenCollapseBlock(!isOpenCollapseBlock)}
                >
                      Versandkosten berechnen
                </p>
              </div>
              <Collapse
                isOpened={isOpenCollapseBlock}
                theme={{
                  collapse: 'CartDataSubtotal-transportation-method-collapse',
                  content: 'CartDataSubtotal-transportation-method-content'
                }}
              >
                <div>
                  <CartDataSubtotalFormik />
                </div>
              </Collapse>
            </div>

            <div className="CartDataSubtotal-common">
                <p className="CartDataSubtotal-common-name">Gesamtsumme</p>
                <p className="CartDataSubtotal-common-price">{toEuroAmount(cartSummary?.deliverySumDelivery)}</p>
            </div>
            <div className="CartDataSubtotal-VATIncluded">
                <p className="CartDataSubtotal-VATIncluded-name">inkl. MwSt.</p>
                <p className="CartDataSubtotal-VATIncluded-price">{+cartSummary?.vatSum === 0 ? "0,00 €" : toEuroAmount(cartSummary?.vatSum)}</p>
            </div>
            <button onClick={() => setStep(1)}>Weiter zur Kasse</button>

        </div>
    )
};

export default CartDataSubtotal;