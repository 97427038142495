import { useDispatch, useSelector } from "react-redux";
import { useStepper } from "../../customHooks/useStepper";
import "./style.css";
import { toEuroAmount, totalSum } from "../CartPaymentsMethod/helpers";
import React from "react";

const CartOrderSuccess = () => {
  
    const { setStep } = useStepper();
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.cart.userData);

  return (
        <div className="CartOrderSuccess">
          {console.log(userData)}
            <div className={"CartOrderSuccess-paymentInfo"}>
              <div className={"CartOrderSuccess-paymentInfo-bank"}>
                {+localStorage.getItem("paymentId") === 1098 && <>
                  <p className={"CartOrderSuccess-paymentInfo-bank-title"}>Unsere Bankverbindung</p>
                  <p className={"CartOrderSuccess-paymentInfo-bank-secTitle"}>Holzklusiv GmbH:</p>
                  <ul className={"CartOrderSuccess-paymentInfo-bank-list"}>
                    <li>Bank: <strong>Sparkasse Gummersbach</strong></li>
                    <li>Kontonummer: <strong>1000547941</strong></li>
                    <li>Bankleitzahl: <strong>38450000</strong></li>
                    <li>IBAN: <strong>DE57 3845 0000 1000 5479 41</strong></li>
                    <li>BIC: <strong>WELADED1GMB</strong></li>
                  </ul>
                </>}
                <div className={"CartOrderSuccess-paymentInfo-bank-products"}>
                  <p className={"CartOrderSuccess-paymentInfo-bank-title"}>Bestelldetails</p>
                  <div className={"CartOrderSuccess-paymentInfo-bank-products-container"}>
                    <div className={"CartOrderSuccess-paymentInfo-bank-products-container-header"}>
                      <p>PRODUCT</p>
                      <p>GESAMTSUMME</p>
                    </div>
                    <div className={"CartOrderSuccess-paymentInfo-bank-products-container-products"}>
                      {userData?.order?.goods && Object.values(userData?.order?.goods)?.length > 0 &&
                        <div className={"CartOrderSuccess-paymentInfo-bank-products-container-products-product"}>
                          {Object.values(userData?.order?.goods).map(product => {
                                return <p>
                                          <strong>
                                            {product.category}:
                                          </strong> {product.name} {product.price ? `${toEuroAmount(product.price)} (inkl. MwSt.)` : ""}
                                       </p>
                          })}
                        </div>
                      }
                      <p className={"CartOrderSuccess-paymentInfo-bank-products-container-summary-item-price"}>
                        {toEuroAmount(userData?.order?.sumForAllWithoutVat)}
                      </p>
                    </div>
                    <div className={"CartOrderSuccess-paymentInfo-bank-products-container-summary"}>
                        <div className={"CartOrderSuccess-paymentInfo-bank-products-container-summary-item"}>
                            <p className={"CartOrderSuccess-paymentInfo-bank-products-container-summary-item-title"}>
                              Zwischensumme:
                            </p>
                            <p className={"CartOrderSuccess-paymentInfo-bank-products-container-summary-item-price"}>
                              {toEuroAmount(userData?.order?.sumForAllWithoutVat)}
                            </p>
                        </div>
                        <div className={"CartOrderSuccess-paymentInfo-bank-products-container-summary-item"}>
                            <p className={"CartOrderSuccess-paymentInfo-bank-products-container-summary-item-title"}>
                              Lieferung:
                            </p>
                            <p className={"CartOrderSuccess-paymentInfo-bank-products-container-summary-item-price"}>
                              {toEuroAmount(userData?.order?.deliverySum)} <br/><span className={"CartOrderSuccess-paymentInfo-bank-products-container-summary-item-title"}>via Lieferung</span>
                            </p>
                        </div>
                        <div className={"CartOrderSuccess-paymentInfo-bank-products-container-summary-item"}>
                            <p className={"CartOrderSuccess-paymentInfo-bank-products-container-summary-item-title"}>
                              Zahlungsmethode:
                            </p>
                            <p className={"CartOrderSuccess-paymentInfo-bank-products-container-summary-item-title"}>
                              {userData?.details?.pay_method}
                            </p>
                        </div>
                        <div className={"CartOrderSuccess-paymentInfo-bank-products-container-summary-item"}>
                            <p className={"CartOrderSuccess-paymentInfo-bank-products-container-summary-item-title"}>
                              Gesamt:
                            </p>
                            <p className={"CartOrderSuccess-paymentInfo-bank-products-container-summary-item-price"}>
                              {toEuroAmount(userData?.details?.total)}
                            </p>
                        </div>
                        <div className={"CartOrderSuccess-paymentInfo-bank-products-container-summary-item"}>
                            <p className={"CartOrderSuccess-paymentInfo-bank-products-container-summary-item-title"}>
                              inkl. MwSt.
                            </p>
                            <p className={"CartOrderSuccess-paymentInfo-bank-products-container-summary-item-price"}>
                              {toEuroAmount(userData?.order?.vatSum)}
                            </p>
                        </div>
                    </div>
                  </div>
                </div>
                {+localStorage.getItem("paymentId") === 1104 && <div className={"CartOrderSuccess-paymentInfo-bank-delivery"}>
                  <p className={"CartOrderSuccess-paymentInfo-bank-title"}>Rechnungsadresse</p>
                  <span className={"CartOrderSuccess-paymentInfo-bank-products-container-summary-item-title"}>
                    {userData?.details?.name}
                  </span>
                  <span className={"CartOrderSuccess-paymentInfo-bank-products-container-summary-item-title"}>
                    {userData?.details?.street} {userData?.details?.room}
                  </span>
                  <span className={"CartOrderSuccess-paymentInfo-bank-products-container-summary-item-title"}>
                    {userData?.details?.index} {userData?.details?.city}
                  </span>
                  <span className={"CartOrderSuccess-paymentInfo-bank-products-container-summary-item-title"}>
                    {userData?.details?.phone}
                  </span>
                  <span className={"CartOrderSuccess-paymentInfo-bank-products-container-summary-item-title"}>
                    {userData?.details?.email}
                  </span>
                </div>}
              </div>
              <div className={"CartOrderSuccess-paymentInfo-order"}>
                <p><strong>Vielen Dank. Deine Bestellung ist eingegangen.</strong></p>
                <ul>
                  <li>Bestellnummer: <strong>{userData?.details?.order_number}</strong></li>
                  <li>Datum: <strong>{userData?.details?.date}</strong></li>
                  {+localStorage.getItem("paymentId") === 1104 && <li>E-mail: <strong>{userData?.details?.email}</strong></li>}
                  <li>Gesamt: <strong>{toEuroAmount(userData?.details?.total)}</strong></li>
                  <li>Zahlungsmethode: <strong>{userData?.details?.pay_method}</strong></li>
                </ul>
              </div>
            </div>
        </div>
  )};

export default CartOrderSuccess;