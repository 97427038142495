import React, { useEffect, useState } from "react";
import { Collapse } from "react-collapse/lib/Collapse";
import { isEmpty } from "./helper";
import { hotTubAPI } from "../../../../api";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../../Loader";
import Radio from "../../../Radio";
import "./style.css";
import { initKlarnaForm } from "../../../../actions/hotTub";

const CartPaymentOptions = () => {
  
  const [radioPaymentValue, setRadioPaymentValue] = useState("1098");
  const [radioCardValue, setRadioCardValue] = useState("DebitCharge");
  
  const isLoadingPaySystemList = useSelector(state => state.cart.isLoadingPaySystemList);
  const paySystemsList = useSelector(state => state.cart.paySystemsList);
  
  const dispatch = useDispatch();
  
  useEffect( () => {
    localStorage.setItem("paymentId", radioPaymentValue);
    if(+radioPaymentValue === 1103) {
      dispatch(initKlarnaForm());
    }
  }, [radioPaymentValue])
  
  useEffect(() => {
    if(!paySystemsList?.some(el => +el.id === +localStorage.getItem("paymentId"))){
      setRadioPaymentValue("1098");
    }
  }, [paySystemsList])

  return (
    <div className="CartPaymentOptions">
      {isLoadingPaySystemList && <div className="CartPaymentOptions-loader"><Loader /></div>}
      <p>ZAHLUNGSART AUSWÄHLEN</p>
        {paySystemsList.map((typePayment, index) => {
          return <div key={typePayment.id} className="CartPaymentOptions-method">
            <Radio
              checked={+radioPaymentValue === +typePayment.id}
              setRadioValue={setRadioPaymentValue}
              label={<>{typePayment.params.Name} {typePayment.params.image &&
                <img src={`https://${window.partnerPath}${typePayment.params.image}`} alt="logo type of payment"/>}
                {!isEmpty(typePayment.params["info_title"]) &&
                  <a href={typePayment.params["info_link"]} target="_blank" rel="noreferrer">{typePayment.params["info_title"]}</a>}</>}
              value={typePayment.id}/>
            <Collapse
              isOpened={+radioPaymentValue === typePayment.id}
              theme={{
                collapse: "CartPaymentOptions-method-collapse",
                content: "CartPaymentOptions-method-content",
              }}>
              <div className="CartPaymentOptions-method-content-data">
                {+radioPaymentValue === typePayment.id && <div id="klarna-payments-container1" style={{height: "max-content", width: "100%"}}></div>}
                <p>{typePayment.params.description}</p>
              </div>
            </Collapse>
          </div>
        })}
    </div>
  );
};

export default CartPaymentOptions;
