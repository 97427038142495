import React from "react";
import {cutSeparator, toEuroAmount, } from "../../helpers";
import "../style.css";
import { getWoodenBoxId } from "../../../../helperForIds";

const CartPaymentProduct = (props) => {

    const { product } = props;
    
    return (
        <div className="CartPaymentOrder-product" key={product.object._main.id}>
            <div className="CartPaymentOrder-product-option">
                <p className="CartPaymentOrder-product-option-name">
                    {product.nameCategory} <span className="CartPaymentOrder-product-option-amount">× {product.count}</span>
                </p>
                {product.object?.base.cart_image && (
                    <div className="CartPaymentOrder-product-icon">
                        <img src={`https://${window.partnerPath}` + `${product.object?.base.cart_image}`} alt="defaultImage" />
                    </div>
                )}
                <div className="CartPaymentOrder-option-text">
                    <p className="CartPaymentOrder-product-option-name">
                        {product.object._main.Name} {product.price ? `(${toEuroAmount(product.price) })` : ""}
                    </p>
                </div>
            </div>
            <p className="CartPaymentOrder-product-price">
                {toEuroAmount(product.priceSum)}
            </p>
        </div>
    );
};

export default CartPaymentProduct;
