import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { getPaySystemsList, setUserData } from "../../../actions/cart";
import { useStepper } from "../../customHooks/useStepper";
import { Loader } from "../../Loader";
import { useEffect } from "react";
import CartOrderCustomerDataForm from "./CartOrderCustomerDataForm";
import CartPaymentsMethod from "../CartPaymentsMethod";
import { getPaypal, updatePrice } from "../../../actions/hotTub";
import "./style.css";

const CartOrderOverview = () => {
  
    const countriesAndCitiesData = useSelector(state => state.hotTub.countriesAndCitiesData);
    const isFormSubmitting = useSelector(state => state.cart.isFormSubmitting);
    const { setStep } = useStepper();
    const dispatch = useDispatch();
    
    useEffect(() => {
      if(!localStorage.getItem("is_address_delivery")){
        dispatch(getPaySystemsList());
        dispatch(updatePrice({
          countryId: localStorage.getItem("countryId"),
          state: localStorage.getItem("state") ? localStorage.getItem("state") : "",
          city: localStorage.getItem("city") ? localStorage.getItem("city") : "",
          postal_code: localStorage.getItem("postal_code") ? localStorage.getItem("postal_code") : "",
          isAddDeliveryPrice: !!localStorage.getItem("postal_code") && localStorage.getItem("delivery") === "Lieferung"
        }));
      }
      
      if(localStorage.getItem("is_address_delivery")){
        dispatch(getPaySystemsList(localStorage.getItem("deliveryCountryId")));
        dispatch(updatePrice({
          countryId: localStorage.getItem("deliveryCountryId"),
          state: localStorage.getItem("state") ? localStorage.getItem("state") : "",
          city: localStorage.getItem("delivery_city") ? localStorage.getItem("delivery_city") : "",
          postal_code: localStorage.getItem("delivery_postal_code") ? localStorage.getItem("delivery_postal_code") : "",
          isAddDeliveryPrice: !!localStorage.getItem("delivery_postal_code") && localStorage.getItem("delivery") === "Lieferung"
        }));
      }
      
    }, [])
  
  const onHandleSubmit = async ( values ) => {
    if (+localStorage.getItem("paymentId") === 1103) {
      window.Klarna.Payments.authorize({
        payment_method_category: "klarna"
      }, function ( res ) {
        if (res?.authorization_token && res?.approved && res?.show_form) {
          dispatch(setUserData(values, res?.authorization_token, setStep));
        } else {
          alert("Error when authorize using Klarna payment method!!!")
        }
      });
    } else if (+localStorage.getItem("paymentId") === 1104) {
      //await dispatch(setUserData(values, "", setStep));
      dispatch(getPaypal(values));
    } else {
      dispatch(setUserData(values, "", setStep));
    }
  }

    const onHandleValidate = (values) => {
        const errors = {};

    if (!values.first_name) {
      errors.first_name = "can't be blank";
    } else if (!/^([A-Z]{1}[a-z]{1,23})$/i.test(values.first_name.trim())) {
      errors.first_name = "Please enter a valid first name";
    }

    if (!values.last_name) {
      errors.last_name = "can't be blank";
    } else if (!/^([A-Z]{1}[a-z]{1,23})$/i.test(values.last_name.trim())) {
      errors.last_name = "Please enter a valid last name";
    }

    if (!values.street) {
      errors.street = "can't be blank";
    }

    if (!values.postal_code) {
      errors.postal_code = "can't be blank";
    } else if (!/^\d{5,10}$/i.test(values.postal_code.trim())) {
      errors.postal_code = "Please enter a correct code";
    }

    if (!values.city) {
      errors.city = "can't be blank";
    }

    if (!values.phone) {
      errors.phone = "can't be blank";
    } else if (!/^([0-9]{1,100})$/i.test(values.phone.trim())) {   //!/^([0-9]{10})$/i
      errors.phone = "Please enter a ten digit phone number";
    }

    if (!values.email) {
      errors.email = "can't be blank";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email.trim())) {
      errors.email = "Invalid email address";
    }

    if (values.is_address_delivery) {
      if (!values.delivery_first_name) {
        errors.delivery_first_name = "can't be blank";
      } else if (!/^([A-Z]{1}[a-z]{1,23})$/i.test(values.delivery_first_name.trim())) {
        errors.delivery_first_name = "Please enter a valid first name";
      }

      if (!values.delivery_last_name) {
        errors.delivery_last_name = "can't be blank";
      } else if (!/^([A-Z]{1}[a-z]{1,23})$/i.test(values.delivery_last_name.trim())) {
        errors.delivery_last_name = "Please enter a valid last name";
      }

      if (!values.delivery_street) {
        errors.delivery_street = "can't be blank";
      }

      if (!values.delivery_postal_code) {
        errors.delivery_postal_code = "can't be blank";
      } else if (!/^\d{5,10}$/i.test(values.delivery_postal_code.trim())) {
        errors.delivery_postal_code = "Please enter a correct code";
      }

      if (!values.delivery_city) {
        errors.delivery_city = "can't be blank";
      }
    }

    return errors;
  };
  
  if(countriesAndCitiesData.length <= 0){
    return <Loader />
  }
  
  let currentCountryObj = countriesAndCitiesData.filter(el => +el.id === +localStorage.getItem("countryId"))[0];
  let currentDeliveryCountryObj = countriesAndCitiesData.filter(el => +el.id === +localStorage.getItem("deliveryCountryId"))[0];

  return (
    <Formik
      initialValues={{
        first_name: localStorage.getItem("first_name") || "",
        last_name: localStorage.getItem("last_name") || "",
        company: localStorage.getItem("company") || "",
        country: currentCountryObj,
        street: localStorage.getItem("street") || "",
        apartment: localStorage.getItem("apartment") || "",
        postal_code: localStorage.getItem("postal_code") || "",
        city: localStorage.getItem("city") || "",
        phone: localStorage.getItem("phone") || "",
        email: localStorage.getItem("email") || "",
        is_address_delivery: localStorage.getItem("is_address_delivery") || false,
        delivery_first_name: localStorage.getItem("delivery_first_name") || "",
        delivery_last_name: localStorage.getItem("delivery_last_name") || "",
        delivery_company: localStorage.getItem("delivery_company") || "",
        delivery_country: currentDeliveryCountryObj,
        delivery_street: localStorage.getItem("delivery_street") || "",
        delivery_apartment: localStorage.getItem("delivery_apartment") || "",
        delivery_postal_code: localStorage.getItem("delivery_postal_code") || "",
        delivery_city: localStorage.getItem("delivery_city") || "",
        notes: localStorage.getItem("notes") || "",
      }}
      validate={onHandleValidate}
      onSubmit={onHandleSubmit}
    >
      {({ submitForm, setValues, setFieldValue, values, errors, touched }) => {
        return (
        <div className="CartOrderOverview">
            {isFormSubmitting && <div className="CartOrderOverview-loader"><Loader /></div>}
            <div className="CartOrderOverview-formik">
                <CartOrderCustomerDataForm
                    submitForm={submitForm}
                    setValues={setValues}
                    setFieldValue={setFieldValue}
                    values={values}
                    errors={errors}
                    touched={touched}
                    countriesAndCitiesData={countriesAndCitiesData}
                />
            </div>
            <CartPaymentsMethod />
        </div>
        );
      }}
    </Formik>
  );
};

export default CartOrderOverview;