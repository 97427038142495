import axios from "axios";

const instance = axios.create({
    baseURL: `https://${window.partnerPath}`,
    headers: {
        'Content-Type': 'application/json',
    }
});

const sid = 3;

export const hotTubAPI = {
    getCalcData: () => instance.get(`/~api/json/catalog.mf/getCalcData?sid=${sid}`),
    getExternalCalcData: (typeId) => instance.get(`/~api/json/catalog.mf/getCalcData/id/${typeId}?sid=${sid}`),
    getRootData: (typeId) => instance.get(`/~api/json/catalog.mf/getRootData/id/${typeId}?sid=${sid}`),
    getCartData: (data) =>  instance.post(`/~api/json/catalog.mf/getCartData?sid=${sid}`, JSON.stringify(data)),
    getCountries: () =>  instance.post(`/~api/json/catalog.mf/getCountries?sid=${sid}`),
    generatePdfLink: (data) => {
        const instance = axios.create({
            baseURL: `https://holzklusiv.xyz:3001`,  /*${process.env.REACT_APP_HOST_API_URL}:3000*/
            headers: {
                'Content-Type': 'application/json',
            }
        });
        return instance.post('/download', JSON.stringify(data))
    },
    getPaySystemsList: (data) => instance.post(`/~api/json/ishop/getPaysystemsList?sid=${sid}`, data),
    removeCartItem: (confId) => instance.get(`/~api/json/ishop/removeCartItemFull/id/${confId}?sid=${sid}`),
    addToCartFull: (data, countryId) => instance.post(`/~api/json/ishop/addToCartFull?sid=${sid}&countryId=${countryId}`, JSON.stringify(data)),
    updatePrice: (data) => instance.post(`/~api/json/ishop/priceUpdate?sid=${sid}`, data),
    getCartItems: (data) => instance.post(`/~api/json/ishop/getCartItemsFull?sid=${sid}`, JSON.stringify(data)),
    submitOrder: (data) => instance.post(`/~api/json/ishop/submitOrder?sid=${sid}`, JSON.stringify(data)),
    getKlarnaInfo: () => instance.get(`/~api/json/catalog.mf/getKlarna?sid=${sid}`),
    getPaypal: (formData) => instance.post(`/~api/json/catalog.mf/getPaypal?sid=${sid}`, formData),
    executeOrderPaypal: (searchParams) => instance.get(`/~api/json/catalog.mf/executeOrderPaypal${searchParams}&sid=${sid}`),
};