import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useStepper } from "../../../customHooks/useStepper";
import CartDataSubtotalFormikForm from "../CartDataSubtotalFormikForm";
import { Loader } from "../../../Loader";
import { updatePrice } from "../../../../actions/hotTub";
/*import "./style.css";*/

const CartDataSubtotalFormik = () => {
  
  const countriesAndCitiesData = useSelector(state => state.hotTub.countriesAndCitiesData);
  const { setStep } = useStepper();
  const dispatch = useDispatch();
  
  const onHandleSubmit = (values) => {
    console.log(values);
    !values.country.isVatIncluded && localStorage.setItem("delivery", "Lieferung");
    localStorage.setItem("deliveryCountryId", values.country.id);
    dispatch(updatePrice({
      countryId: values.country.id,
      state: values?.state ? values.state.id : "",
      city: values.city,
      postal_code: values.postal_code,
      isAddDeliveryPrice: !!values.postal_code && localStorage.getItem("delivery") === "Lieferung"
    }))
    /*setStep(2);*/
  }
  
  const onHandleValidate = (values) => {
    return {};
  };
  
  if(countriesAndCitiesData.length <= 0){
    return <Loader />
  }
  
  let currentCountryObj = countriesAndCitiesData.filter(el => +el.id === +localStorage.getItem("countryId"))[0];
  
  return (
    <Formik
      initialValues={{
        country: currentCountryObj,
        state: +localStorage.getItem("state") ? currentCountryObj?.cities.filter(item => +item.id === +localStorage.getItem("state"))[0] : "",
        city: localStorage.getItem("city") ? localStorage.getItem("city") : "",
        postal_code: localStorage.getItem("postal_code") ? localStorage.getItem("postal_code") : ""
      }}
      validate={onHandleValidate}
      onSubmit={onHandleSubmit}
    >
      {({ submitForm, setValues, setFieldValue, values, errors, touched }) => {
        return (
          <div className="CartDataSubtotalFormik">
            <CartDataSubtotalFormikForm submitForm={submitForm}
                                        setValues={setValues}
                                        setFieldValue={setFieldValue}
                                        values={values}
                                        errors={errors}
                                        touched={touched}
                                        countriesAndCitiesData={countriesAndCitiesData}
            />
          </div>
        );
      }}
    </Formik>
  );
};

export default CartDataSubtotalFormik;