import CheckIcon from "../CheckIcon";
import './style.css';


const CartModalContainerTitle = () => {
    return <div className="CartModalContainerTitle">
        <CheckIcon />
        <p>Hot Tub Individuell“ wurde deinem Warenkorb hinzugefügt.</p>
    </div>
}

export default CartModalContainerTitle;