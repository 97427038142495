import { useState } from "react";
import { useFormikContext } from "formik";
import Checkbox from "../../Checkbox";
import CartPaymentOptions from "./CartPaymentOptions";
import CartPaymentOrder from "./CartPaymentOrder";
import { useSelector } from "react-redux";
import { Loader } from "../../Loader";
import cn from 'classnames';
import "./style.css";

const CartPaymentsMethod = () => {
  
  const labelAgreement = (
    <span className="CartPaymentsMethod-order-agreement-label">
      Mit deiner Bestellung erklärst du dich mit unseren <a href={process.env.REACT_APP_TERMS_CONDITIONS} target="_blank" rel="noreferrer">
      Allgemeinen Geschäftsbedingungen </a>, <a href={process.env.REACT_APP_CANCELLATION_POLICY} target="_blank" rel="noreferrer">
      Widerrufsbestimmungen</a> und <a href={process.env.REACT_APP_PRIVACY_POLICY} target="_blank" rel="noreferrer">Datenschutzbestimmungen
      </a> einverstanden.
    </span>
  );

  const { values, submitForm } = useFormikContext();

  const [isAgreement, setIsAgreement] = useState(true);
  
  const isLoadingUpdatePrice = useSelector(state => state.hotTub.isLoadingUpdatePrice);
  const isPayPalLoading = useSelector(state => state.hotTub.isPayPalLoading);

  const onHandlerSubmit = () => {
    submitForm();
  };

  return (
    <div className="CartPaymentsMethod">
      <div className="CartPaymentsMethod-box">
        <CartPaymentOptions />
        <div className="CartPaymentsMethod-order">
          {isLoadingUpdatePrice && <div className="CartPaymentsMethod-order-loader"><Loader /></div>}
          <div className="CartPaymentsMethod-order-agreement">
            <p>DEINE BESTELLUNG</p>
            <Checkbox
              checked={isAgreement}
              label={labelAgreement}
              setChecked={setIsAgreement}
              required={true}
            />
          </div>
          <CartPaymentOrder />
        </div>
        <button
          className={cn("CartPaymentsMethod-box-button", isPayPalLoading && "loader")}
          onClick={onHandlerSubmit}
        >{isPayPalLoading ? <Loader /> : "Jetzt kaufen"}
        </button>
      </div>
    </div>
  );
};

export default CartPaymentsMethod;
