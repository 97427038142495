import { Form } from "formik";
import Select from "../../../Select";
import TextField from "../../../TextField";
import "./style.css"

const CartDataSubtotalFormikForm = (props) => {
  
  const { values, setFieldValue, errors, touched, countriesAndCitiesData } = props;
  const handleCountryChange = (country) => {
    setFieldValue("country", country);
    setFieldValue("state", "");
  };
  
  const handleStateChange = (state) => {
    setFieldValue("state", state);
  };
  const handleCityChange = (event) => {
    setFieldValue("city", event.target.value);
  };
  const handlePostalCodeChange = (event) => {
    setFieldValue("postal_code", event.target.value);
  };
  
  return (
    <Form className="CartDataSubtotalFormikForm">
      <div className="CartDataSubtotalFormikForm-country-box">
        <Select
          autoComplete="off"
          name="country"
          type="text"
          /*label="Land / Region"*/
          /*required={true}*/
          options={countriesAndCitiesData}
          value={values.country.name}
          onChange={handleCountryChange}
        />
      </div>
      {
        values?.country?.cities?.length > 0 &&
        <div className="CartDataSubtotalFormikForm-country-box">
          <Select
            autoComplete="off"
            name="state"
            type="text"
            placeholder="Bundesland / Landkreis"
            /*label="Bundesland / Landkreis"
            required={true}*/
            options={values?.country?.cities}
            value={values.state.name}
            onChange={handleStateChange}
          />
        </div>
      }
      <div className="CartDataSubtotalFormikForm-firstBlock-box">
        <TextField
          autoComplete="off"
          name="city"
          type="text"
          /*label="Stadt"*/
          placeholder="Stadt"
          /*required={true}*/
          value={values.city}
          onChange={handleCityChange}
          // error={errors.first_name && touched['first_name']}
        />
      </div>
      <div className="CartDataSubtotalFormikForm-firstBlock-box">
        <TextField
          autoComplete="off"
          name="postal_code"
          type="text"
          /*label="Postleitzahl"*/
          placeholder="Postleitzahl"
          /*required={true}*/
          value={values.postal_code}
          onChange={handlePostalCodeChange}
          // error={errors.first_name && touched['first_name']}
        />
      </div>
      <button className="CartDataSubtotalFormikForm-submit">Aktualisieren</button>
    </Form>
  );
};

export default CartDataSubtotalFormikForm;
